<template>
  <div id="TableContainer">
    <div>
      <Header table="items"
              v-bind:manufacturer-id="manufacturerId"
              v-bind:hidePanel="hidePanel"
              @search="search"
              @deleteAll="deleteItems"
              @cloneSelected="cloneItems"
              @showActiveItems="showActiveItems"
              ref="counter"/>
    </div>

    <vue-confirm-dialog></vue-confirm-dialog>

    <div id="table">
      <ItemList v-bind:items="changedItems"
                v-bind:manufacturer-id="manufacturerId"
                @setHeaderVisible="setHeaderVisible"
                @setPanelVisible="setPanelVisible"/>
    </div>
  </div>
</template>
<script>

import axios from "axios";
import ItemList from "./ItemList";
import Header from "../Header";
import Link from "../utils/Link";
import VueConfirmDialog from "vue-confirm-dialog";
import Vue from "vue";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
import SideBarMenu from "../SideBarMenu";
import VueToast from "vue-toast-notification";
import ui from "../utils/ui";
import moment from "moment";

let tableHeaderPosition = [0];

export default {
  components: {
    ItemList: ItemList,
    Header: Header,
  },

  props: {
    manufacturerId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      items: [],
      hidePanel: true,
      array: [],
      showOnlyActiveItems: true,
      isRoleAdmin: false,
      lastQuery: (x) => x
    };
  },

  computed: {
    changedItems: function () {
      return this.items.filter(x =>
        ((this.showOnlyActiveItems) ? (x.status !== "DELETED") : true)
        && this.lastQuery(x, this.isInclude)
      );
    }
  },

  created() {
    window.addEventListener("scroll", this.scrolling);
    window.addEventListener('resize', this.windowResize);
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrolling);
    window.addEventListener('resize', this.windowResize);
  },

  beforeMount() {
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
    this.getItemsByManufacturerId();
    this.showActiveItems();
  },

  mounted() {
  },

  methods: {
    getItemsByManufacturerId() {
      const headers = Link.methods.getHeaders();
      axios.get(
        Link.methods.getItemsByManufacturer(this.manufacturerId),
        {headers}
      ).then(res => {
        this.items = res.data;
      }).catch(() => {
        this.createToast(this.$t("itemsByManufacturerId.itemsNotFound"), "error")
      })
    },

    search(qsearch) {
      this.lastQuery = qsearch;
    },

    convertAndConcatStrings(s1, s2) {
      s1 = s1.toString().concat("x");
      return s1.concat(s2.toString());
    },

    isInclude(item, query) {
      return this.fieldContain(query, item.id.toString())
          || this.fieldContain(query, item.system)
          || this.fieldContain(query, item.manufacturer.name)
          || this.fieldContain(query, item.mountType)
          || this.dimensionContain(query, item.width, item.depth, item.thickness)
          || this.dimensionContain(query, item.horizontalStep, item.verticalStep)
          || this.fieldContain(query, item.userName)
          || this.statusContain(query, item.status)
          || this.dateFieldContain(query, item.updated, 'DD.MM.YYYY HH:mm');
    },

    fieldContain(query, field) {
      if (field === null) {
        return false;
      }
      return field.toLowerCase().indexOf(query) !== -1;
    },

    statusContain(query, status) {
      if (status === null) {
        return false;
      }
      return (
          status === "ACTIVE" ? this.$t("soloItem.active") : this.$t("soloItem.deleted")
      ).toLowerCase().indexOf(query) !== -1;
    },

    dateFieldContain(query, field, dateFormat) {
      if (field !== null) {
        return moment(field).format(dateFormat).indexOf(query) !== -1;
      }
    },

    dimensionContain(query, ...dim) {
      let dimensions = dim[0].toString();
      if (dim.length > 1) {
        for (let i = 0; i < dim.length - 1; i++) {
          dimensions = this.convertAndConcatStrings(dimensions, dim[i + 1]);
        }
      }
      return dimensions.indexOf(query) !== -1;
    },

    setPanelVisible(array) {
      this.array = array;
      this.$refs.counter.changeLabel(array.length, "items");
      this.hidePanel = false;
    },

    setHeaderVisible() {
      this.hidePanel = true;
    },

    deleteItems() {
      this.$confirm(
        {
          message: this.$t("itemsByManufacturerId.confirmMessage"),
          button: {
            no: this.$t("itemsByManufacturerId.confirmNo"),
            yes: this.$t("itemsByManufacturerId.confirmYes"),
          },
          callback: confirm => {
            if (confirm) {
              const headers = Link.methods.getHeaders();
              const promises = [];
              for (let i = 0; i < this.array.length; i++) {
                promises.push(axios.delete(Link.methods.getItemDeleteUrl(this.array[i]), {headers}));
              }
              Promise.all(promises).then(() => {
                this.$emit('removeCheckboxes');
                this.array = [];
                this.getItemsByManufacturerId();
                this.setHeaderVisible();
              }).catch(() => {
                this.createToast(this.$t("mainItem.itemsDeleteErrorToast"), "error")
              })
            }
          }
        }
      )
    },

    cloneItems() {
      const headers = Link.methods.getHeaders();
      const promises = [];
      for (let i = 0; i < this.array.length; i++) {
        promises.push(
          axios.get(
            Link.methods.getItemById(this.array[i]),
            {headers}
          ).then(res =>
            axios.post(
              Link.methods.getItemsCreateUrl(),
              res.data,
              {headers}
            )
          )
        )
      }

      Promise.all(promises).then( () => {
        this.$emit('removeCheckboxes');
        this.array = [];
        this.getItemsByManufacturerId()
        this.setHeaderVisible();
      }).catch(() => {
        this.createToast(this.$t("itemsByManufacturerId.itemNotCloned"), "error")
      })
    },

    showActiveItems() {
      const filter = window.localStorage.getItem("activeManufacturesItemsFilter");
      if (filter != null) {
        this.showOnlyActiveItems = (filter === 'true');
      }
      if (!this.isRoleAdmin) {
        this.showOnlyActiveItems = true;
      }
    },

    windowResize() {
      this.alignColumns();
    },

    scrolling() {
      this.alignColumns();
      ui.methods.stickHeader(tableHeaderPosition);
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },

    alignColumns() {
      let rowWidth = 0;
      rowWidth += ui.methods.copyElementWidthIfDifferent('.idTd', 'th.id');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.systemTd', 'th.system');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.mountTypeTd', 'th.mountType');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.wxdxtTd', 'th.wxdxt');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.l1xl2Td', 'th.l1xl2');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.imageThTd', 'th.imageCol');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.dateTd', 'th.date');
      if (this.isRoleAdmin) {
        rowWidth += ui.methods.copyElementWidthIfDifferent('.checkboxTd', 'th.checkbox');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.statusTd', 'th.status');
      }
      ui.methods.copyElementWidthIfDifferent(rowWidth, '#tableHead');
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

ItemList.tbody {
  overflow: visible;
}

#TableContainer {
  margin-top: 3rem;
}

</style>